export const RoutePostMerchflowApplyToBasesByCopyStores =
  "/merchflow/apply-to-bases-by-copy-stores";

export interface PayloadPostMerchflowApplyToBasesByCopyStores {
  base_pog_id: number;
}

export interface ResponsePostMerchflowApplyToBasesByCopyStores {
  store_pog_trigger_id: number;
}
