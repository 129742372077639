import { PogNonCircular } from "@CommonTypes/merchflow/pog/pog";
import { S2Solution } from "@CommonTypes/merchflow/pog/solution";
import { Pagination } from "@CommonTypes/backend/pagination";
import { OneStoreViewThumbnail } from "@CommonTypes/merchflow/OneStoreView";

export const RouteGetMerchflows_MerchflowId_Stores_StoreCode_ =
  "/merchflows/:merchflowId/stores/:storeCode";

export type ResponseGetMerchflows_MerchflowId_Stores_StoreCode_ = Pagination<StoreVersionsView>;

export interface StoreVersionsView {
  s2Solution?: S2Solution;
  basePog?: {
    basePogId: number;
    basePogData: PogNonCircular;
  };
  storePog: {
    storePogId: number;
    storePogData?: PogNonCircular;
    isApproved: boolean;
    thumbnail: OneStoreViewThumbnail;
    status:
      | "NOT_STARTED"
      | "POGGER_IN_PROGRESS"
      | "POGGERIZE_IN_PROGRESS"
      | "DONE"
      | "ERROR"
      | "UPDATE_IN_PROGRESS";
    error?: string;
  };
  filterConfigId?: number;
  templateId?: string;
  project_id?: number | null;
}
