import { Color, Flex, Text } from "src/elements";
import styled from "styled-components";

const HandleContainer = styled(Flex)<{ percentage: number }>`
  position: absolute;
  bottom: 1px;
  left: ${({ percentage }) => `calc(${percentage}% - ${(percentage / 100) * 13}px)`};
  z-index: 1;
  pointer-events: none;
`;

const HandleElement = styled(Flex)<{ isDisabled: boolean }>`
  width: 13px;
  height: 13px;
  background-color: ${({ isDisabled }) => (isDisabled && Color.lightGray) || Color.white};
  pointer-events: none;
  gap: 2px;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0 0 1px ${Color.spaceGrayHover}) drop-shadow(0 0 1px ${Color.spaceGrayHover});
`;

const Pointer = styled(Flex)<{ isDisabled: boolean }>`
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: ${({ isDisabled }) =>
    `4px solid ${(isDisabled && Color.lightGray) || Color.white}`};
  position: absolute;
  top: -4px;
  left: 0px;
`;

const Gripper = styled(Flex)`
  width: 1px;
  height: 8px;
  background-color: ${Color.spaceGrayHover};
`;

const Value = styled(Flex)`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -23px;
  justify-content: center;
`;

interface Props {
  percentage: number;
  value: number | string;
  withoutValueLabel?: boolean;
  isDisabled?: boolean;
}

export const Handle = ({ percentage, value, withoutValueLabel, isDisabled = false }: Props) => (
  <HandleContainer percentage={percentage}>
    <HandleElement isDisabled={isDisabled}>
      <Pointer isDisabled={isDisabled} />
      <Gripper />
      <Gripper />
      <Gripper />
    </HandleElement>

    {!withoutValueLabel && (
      <Value>
        <Text variant="small2" color={(isDisabled && Color.textDisabled) || Color.textSecondary}>
          {value}
        </Text>
      </Value>
    )}
  </HandleContainer>
);
