import styled from "styled-components";
import { Flex } from "../Box/Box";
import { Color } from "../Color/Color";
import { Tab } from "./components/Tab";

const TabsContainer = styled(Flex)<{ isDisabled?: boolean }>`
  border: ${({ isDisabled }) => `1px solid ${isDisabled ? Color.textDisabled : Color.primary}`};
  border-radius: 3px;
  overflow: hidden;
  min-height: max-content;
`;

interface Props<T> {
  tabs: { label: string; value: T }[];
  value: T;
  setValue: (value: T) => void;
  isDisabled?: boolean;
}

export const Tabs = <T,>({ tabs, value, setValue, isDisabled }: Props<T>) => (
  <TabsContainer isDisabled={isDisabled}>
    {tabs.map((tab, i) => (
      <Tab
        key={i}
        label={tab.label}
        value={tab.value}
        setValue={setValue}
        isActive={tab.value === value}
        isDisabled={isDisabled}
      />
    ))}
  </TabsContainer>
);
