import { PlanogramBay, PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { useAtom, useAtomValue } from "jotai";
import React, { useRef } from "react";
import {
  getShelfHeight,
  getShelfXLeft,
  getShelfXRight,
  getShelfYBottom,
  isShelfOverflowing,
} from "src/omni-common/utils/pog/shelf";
import styled from "styled-components";

import { usePOG } from "../../store/actions";
import { DragBounds, DragModifications, PogAtom, Scaleable } from "../../store/types";
import { usePlanogramShelfActions } from "../../types";
import { Draggable } from "../Draggable/Draggable";
import { Dropper } from "../Item/Dropper";
import { Item } from "../Item/Item";
import { onDropOverToShelf } from "../Item/store/utils";
import { Overflowing } from "./Overflowing";
import { ShelfBottom } from "./ShelfBottom";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import {
  planogramFixturesModeAtom,
  planogramHoveredShelfAtom,
  planogramSelectedShelfAtom,
} from "src/components/PlanogramExtensions/store/atoms";

const ShelfInner = styled.div<{ height: number; scaleY: number }>`
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Items = styled.div<{
  fixturesMode: boolean;
}>`
  height: 0;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  opacity: ${({ fixturesMode }) => fixturesMode && "0.5"};
  pointer-events: ${({ fixturesMode }) => fixturesMode && "none"};

  // First element is dropper, ignore that
  // Second element is always first item in the shelf.
  > *:nth-of-type(2) {
    flex-grow: 0;
  }
`;

const ItemsAnchor = styled.div``;

const ShelfBottoms = styled.div`
  display: flex;
`;

interface Props extends Scaleable {
  currentStoreCode?: string;
  bay: PlanogramBay;
  shelves: PlanogramShelf[];
  bounds: DragBounds;
  isEditable: boolean;
  isPog: boolean;
  highlightedItems: string[];
  pogAtom: PogAtom;
  setIsModified: (modified: boolean) => void;
  isDuplicateDisabled?: boolean;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
  pdfMode: boolean;
}

export const MergedShelf = ({
  currentStoreCode,
  bay,
  shelves,
  bounds,
  isEditable,
  isPog,
  highlightedItems,
  scaleX,
  scaleY,
  pogAtom,
  setIsModified,
  isDuplicateDisabled,
  availableStores,
  pdfMode,
}: Props) => {
  const { moveShelf } = usePlanogramShelfActions(pogAtom);
  const { dropItemToShelf } = usePOG(pogAtom);

  const [fixturesMode] = useAtom(planogramFixturesModeAtom);
  const [selectedShelf, setSelectedShelf] = useAtom(planogramSelectedShelfAtom);
  const hoveredShelf = useAtomValue(planogramHoveredShelfAtom);

  const firstShelf = shelves[0];
  const lastShelf = shelves[shelves.length - 1];
  const shelfHeight = getShelfHeight(firstShelf);
  const shelfThickness = firstShelf.thickness;
  const isNewShelf = selectedShelf?.isNew === true;
  const isSelected = Boolean(shelves.find((shelf) => shelf.uniqueId === selectedShelf?.uniqueId));

  const items: PlanogramItem[] = shelves.reduce(
    (items, shelf) => [...items, ...shelf.items],
    [] as PlanogramItem[],
  );

  const refItems = useRef<HTMLDivElement>(null);

  const getBounds = () => ({
    xLeft: getShelfXLeft(firstShelf),
    xRight: getShelfXRight(lastShelf),
    yBottom: bay.baseHeight,
    yTop: isNewShelf ? bay.notchOffset + (bay.maxNotch - 1) * bay.notchSpacing : bounds.yTop,
  });
  const onModify = ({ yBottom }: DragModifications) => {
    setIsModified(true);

    moveShelf(firstShelf, yBottom / firstShelf.bay.notchSpacing + 1);
  };

  const onDropOver = () => {
    setIsModified(true);

    onDropOverToShelf({
      refItems,
      onDrop,
    });
  };

  const onDrop = (item: PlanogramItem) => {
    setIsModified(true);

    dropItemToShelf({
      shelfId: firstShelf.uniqueId,
      item,
    });
  };

  const setSelected = () => {
    setSelectedShelf(hoveredShelf || firstShelf);
  };

  return (
    <Draggable
      uniqueId={firstShelf.uniqueId}
      xLeft={getShelfXLeft(firstShelf)}
      xRight={getShelfXRight(lastShelf)}
      yBottom={getShelfYBottom(firstShelf)}
      yTop={getShelfYBottom(firstShelf)}
      scaleX={scaleX}
      scaleY={scaleY}
      bounds={getBounds()}
      snappables={[]}
      isDraggable={fixturesMode && isEditable}
      direction="vertical"
      moveBy={bay.notchSpacing}
      onModify={onModify}
      onClick={setSelected}
    >
      <ShelfInner height={shelfHeight} scaleY={scaleY}>
        {isPog && items.length <= 1 && (
          <Dropper
            side="left"
            width="100%"
            height={`calc(${shelfHeight - shelfThickness}px * ${scaleY})`}
            bottom={`calc(${shelfThickness}px * ${scaleY})`}
            onDropOver={onDropOver}
            onDrop={onDrop}
          />
        )}

        {!pdfMode && isShelfOverflowing(firstShelf) && (
          <Overflowing
            shelfHeight={shelfHeight}
            shelfThickness={firstShelf.thickness}
            scaleY={scaleY}
          />
        )}

        <Items ref={refItems} fixturesMode={fixturesMode}>
          <ItemsAnchor />

          {items.map((item, i) => (
            <React.Fragment key={i}>
              <Item
                currentStoreCode={currentStoreCode}
                item={item}
                scaleX={scaleX}
                scaleY={scaleY}
                isEditable={isEditable && !fixturesMode}
                isHighlighted={highlightedItems.includes(item.uniqueId)}
                isScrolledToView={
                  highlightedItems.length === 1 && highlightedItems.includes(item.uniqueId)
                }
                pogAtom={pogAtom}
                setIsModified={setIsModified}
                isDuplicateDisabled={isDuplicateDisabled}
                availableStores={availableStores}
              />
            </React.Fragment>
          ))}
        </Items>

        <ShelfBottoms>
          {shelves.map((shelf, i) => (
            <ShelfBottom
              key={i}
              shelf={shelf}
              scaleX={scaleX}
              scaleY={scaleY}
              isSelected={isSelected}
              pogAtom={pogAtom}
              showShelfName={pdfMode}
            />
          ))}
        </ShelfBottoms>
      </ShelfInner>
    </Draggable>
  );
};
