import { useAtom } from "jotai";
import { Flex, Scroller, Text } from "src/elements";
import { omniAtom } from "src/utils/atoms";
import { highlightsModeAtom } from "../Planogram/store/atoms";

export interface PlanogramLegend {
  formula: string;
  formulaColorsMap: { [key: string]: string };
}

export const planogramLegendAtom = omniAtom({
  key: "planogram/legend",
  value: { formula: "", formulaColorsMap: {} } as PlanogramLegend,
});

export const PlanogramLegend = () => {
  const [legend] = useAtom(planogramLegendAtom);
  const [highlightsMode] = useAtom(highlightsModeAtom);

  return (
    <Flex minHeight="34px">
      <Scroller maxHeight="34px">
        <Flex minHeight="18px" gap="15px">
          {highlightsMode &&
            Object.entries(legend.formulaColorsMap).map((entry) => (
              <Flex flexWrap="nowrap" gap="3px" align="center">
                <Flex
                  minWidth="8px"
                  minHeight="8px"
                  borderRadius="50%"
                  background={entry[1]}
                ></Flex>

                <Text whiteSpace="nowrap">{entry[0].replaceAll('"', "")}</Text>
              </Flex>
            ))}
        </Flex>
      </Scroller>
    </Flex>
  );
};
