import styled from "styled-components";
import { SubgridBodyColumn } from "../store/types";
import { Grid } from "src/elements";
import { SubgridCell } from "./SubgridCell";
import { SubgridRow } from "./SubgridRow";
import React from "react";

const Body = styled(Grid)`
  grid-column: 1 / -1;
  grid-template-columns: subgrid;

  > * {
    display: grid;
    grid-column: 1 / -1;
    grid-template-columns: subgrid;
  }
`;

interface Props<T> {
  bodyColumns: SubgridBodyColumn<T>[];
  data: T[];
  rowRenderer?: (row: T) => React.ReactNode;
}

export const SubgridBody = <T extends object>({ bodyColumns, data, rowRenderer }: Props<T>) => (
  <Body>
    {data.map((dataRow, i) =>
      rowRenderer ? (
        <React.Fragment key={i}>{rowRenderer(dataRow)}</React.Fragment>
      ) : (
        <SubgridRow key={i} data-testid="subgrid-row">
          {bodyColumns.map((bodyColumn, j) => {
            if (bodyColumn.renderer) {
              return (
                <SubgridCell
                  key={j}
                  leftBorder={j === 0}
                  justify={bodyColumn.justify}
                  align={bodyColumn.align}
                >
                  {bodyColumn.renderer({
                    row: dataRow,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    value:
                      bodyColumn.id === "index"
                        ? i + 1
                        : //@ts-ignore
                          dataRow[bodyColumn.id],
                    index: i,
                  })}
                </SubgridCell>
              );
            }

            return (
              <SubgridCell
                key={j}
                leftBorder={j === 0}
                justify={bodyColumn.justify}
                align={bodyColumn.align}
              >
                {
                  //@ts-ignore
                  bodyColumn.id === "index" ? i + 1 : dataRow[bodyColumn.id]
                }
              </SubgridCell>
            );
          })}
        </SubgridRow>
      ),
    )}
  </Body>
);
