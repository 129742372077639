import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Color,
  Flex,
  Icon,
  Input,
  Scroller,
  Search,
  Select,
  SubgridTable,
  Text,
} from "src/elements";
import { MerchflowReviewUpdateStep, ProductUpdateAdd, ProductUpdates } from "../store/types";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RouteGetInputsProductsAll } from "@CommonApi/inputs/products/all";
import { PageStatus, Tooltip } from "src/components";
import { productToReadableFormat } from "../store/utils";
import { useModals } from "src/components/Modals";
import { FlowUpdateProduct } from "@CommonApi/merchflow/_merchflowId_/flow-update/products";
import { ProductToInsert } from "@CommonApi/merchflow/_merchflowId_/flow-update";

interface Props {
  addableProducts: FlowUpdateProduct[];
  removableProducts: FlowUpdateProduct[];
  setStep: (mode: MerchflowReviewUpdateStep) => void;
  setProductUpdates: Dispatch<SetStateAction<ProductUpdates>>;
}

type SelectableProductToAddType = {
  [key: string]: {
    isChecked: boolean;
    product: FlowUpdateProduct;
    adjacentPosition: "LEFT" | "RIGHT";
    adjacentProduct: FlowUpdateProduct | null;
    facings: string;
    reducibleProduct: FlowUpdateProduct | null;
    fitStrategy: ProductToInsert["fit_strategy"];
  };
};

export const Step3CAddProducts = ({
  addableProducts,
  removableProducts,
  setStep,
  setProductUpdates,
}: Props) => {
  const { openConfirmModal } = useModals();
  const { isInlineLoading } = useInlineLoaders();

  const [selectedProducts, setSelectedProducts] = useState<SelectableProductToAddType>({});
  const [isModified, setIsModified] = useState(false);
  const [search, setSearch] = useState("");

  const isProductsLoading = isInlineLoading(RouteGetInputsProductsAll);

  useEffect(() => {
    if (!addableProducts) return;

    setSelectedProducts(() => {
      const predefinedSelectedProducts: SelectableProductToAddType = {};

      for (const product of addableProducts) {
        predefinedSelectedProducts[product.product_code] = {
          isChecked: false,
          product,
          adjacentPosition: "LEFT",
          adjacentProduct: null,
          facings: "",
          reducibleProduct: null,
          fitStrategy: "KEEP_ALL_FACINGS",
        };
      }

      return predefinedSelectedProducts;
    });
  }, [addableProducts]);

  const onSave = () => {
    setProductUpdates((productUpdates) => [
      ...productUpdates,
      ...Object.values(selectedProducts)
        .filter(
          (selectedProduct) =>
            selectedProduct.isChecked && selectedProduct.adjacentProduct !== null,
        )
        .map(
          (selectedProduct) =>
            ({
              type: "add",
              productToAdd: selectedProduct.product,
              adjacentPosition: selectedProduct.adjacentPosition,
              adjacentProduct: selectedProduct.adjacentProduct!,
              facings: selectedProduct.facings,
              reducibleProduct: selectedProduct.reducibleProduct,
              fitStrategy: selectedProduct.fitStrategy,
              pnlDecision: "DEFAULT",
            }) as ProductUpdateAdd,
        ),
    ]);

    setStep("STEP_3_PRODUCT_REVIEW");
  };

  const openInformationModal = () => {
    openConfirmModal({
      title: "How we make space for added products",
      description: (
        <Flex column padding="10px" gap="5px">
          <Flex margin="0 0 10px 0">
            <Text variant="small1" color={Color.textMain}>
              We make sure there is enough space on the shelves for new products by doing these in
              this order:
            </Text>
          </Flex>

          <Text variant="small2">
            1. Decrease facings of products over minimum required dos and mos
          </Text>

          <Text variant="small2">
            2. Decrease facings of products such that we may dip under dos
          </Text>

          <Text variant="small2">
            3. Decrease facings of products such that we may dip under mos and dos
          </Text>

          <Flex margin="10px 0 0 0">
            <Text variant="small2">
              If the above were unsuccessful, then new products may not be added to stores.
            </Text>
          </Flex>
        </Flex>
      ),
      buttons: [{ label: "OK", color: "greenSmoke" }],
    });
  };

  const filteredSelectableProducts = useMemo(() => {
    return Object.values(selectedProducts)
      .filter((p) =>
        search.length > 0
          ? p.product.product_code.toLowerCase().includes(search.toLowerCase()) ||
            p.product.name.toLowerCase().includes(search.toLowerCase())
          : true,
      )
      .sort((p1, p2) => (p1.product.product_code < p2.product.product_code ? -1 : 1));
  }, [selectedProducts, search]);

  const noSelected = useMemo(() => {
    return (
      Object.values(selectedProducts).find((p) => p.isChecked && p.adjacentProduct !== null) ===
      undefined
    );
  }, [selectedProducts]);

  const hasPartiallySelected = useMemo(() => {
    return (
      Object.values(selectedProducts).find((p) => p.isChecked && p.adjacentProduct === null) !==
      undefined
    );
  }, [selectedProducts]);

  return (
    <Flex column flexGrow gap="20px">
      <Flex column flexGrow gap="20px">
        <Text color={Color.primary} variant="h4">
          Please select an available product to be added. We will reduce facings of existing
          products to make room for this addition.
          <Flex display="inline-flex" position="relative">
            <Flex position="absolute" bottom="4px" onClick={openInformationModal}>
              <Icon name="info" color={Color.primary} size={12} />
              <Tooltip>How do we do that?</Tooltip>
            </Flex>
          </Flex>
        </Text>

        {isProductsLoading ? (
          <PageStatus icon="spinner" label="Loading available products..." />
        ) : (
          <Flex column flexGrow gap="10px">
            <Search width="200px" search={search} setSearch={setSearch} />

            <Scroller>
              <SubgridTable
                columns={[
                  {
                    id: "selected",
                    header: "",
                    width: "40px",
                    justify: "center",
                    renderer: (data) => (
                      <Checkbox
                        name="addable-product"
                        isChecked={selectedProducts[data.row.product.product_code].isChecked}
                        setIsChecked={(isChecked) => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              isChecked,
                            },
                          }));
                          setIsModified(true);
                        }}
                      />
                    ),
                  },
                  {
                    header: "Addables",
                    id: "product_code",
                    align: "center",
                    width: "200px",
                    renderer: (data) => productToReadableFormat(data.row.product),
                  },
                  {
                    id: "adjacent_position",
                    header: "Adjacency",
                    renderer: (data) => (
                      <Select
                        value={data.row.adjacentPosition}
                        setValue={(value: "LEFT" | "RIGHT") => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              adjacentPosition: value,
                            },
                          }));
                          setIsModified(true);
                        }}
                        options={[
                          { label: "LEFT", value: "LEFT" },
                          {
                            label: "RIGHT",
                            value: "RIGHT",
                          },
                        ]}
                        isDisabled={!data.row.isChecked}
                      />
                    ),
                  },
                  {
                    id: "adjacent_product",
                    header: "Adjacent Product",
                    width: "200px",
                    renderer: (data) => (
                      <Select
                        value={selectedProducts[data.row.product.product_code].adjacentProduct!}
                        placeholder={data.row.isChecked ? "Product" : ""}
                        setValue={(value) => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              adjacentProduct: value,
                            },
                          }));
                          setIsModified(true);
                        }}
                        options={(removableProducts || []).map((product) => ({
                          label: productToReadableFormat(product),
                          value: product,
                        }))}
                        isSearchable
                        isDisabled={!data.row.isChecked}
                      />
                    ),
                  },
                  {
                    header: "Facings",
                    id: "facings",
                    width: "80px",
                    renderer: (data) => (
                      <Input
                        placeholder="Auto"
                        value={data.row.facings}
                        setValue={(value) => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              facings: value,
                            },
                          }));
                          setIsModified(true);
                        }}
                        isDisabled={!data.row.isChecked}
                      />
                    ),
                  },
                  {
                    header: "Product to reduce",
                    id: "reducible_product",
                    width: "200px",
                    renderer: (data) => (
                      <Select
                        value={selectedProducts[data.row.product.product_code].reducibleProduct}
                        setValue={(value) => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              reducibleProduct: value,
                            },
                          }));
                          setIsModified(true);
                        }}
                        options={(removableProducts || []).map((product) => ({
                          label: productToReadableFormat(product),
                          value: product,
                        }))}
                        isSearchable
                        isDisabled={!data.row.isChecked}
                      />
                    ),
                  },
                  {
                    header: "Fit Strategy",
                    id: "fit_strategy",
                    width: "250px",
                    renderer: (data) => (
                      <Select
                        value={selectedProducts[data.row.product.product_code].fitStrategy}
                        setValue={(fitStrategy) => {
                          setSelectedProducts((selectedProducts) => ({
                            ...selectedProducts,
                            [data.row.product.product_code]: {
                              ...selectedProducts[data.row.product.product_code],
                              fitStrategy: fitStrategy as ProductToInsert["fit_strategy"],
                            },
                          }));
                          setIsModified(true);
                        }}
                        options={[
                          {
                            label: "No other products to be reduced",
                            value: "KEEP_ALL_FACINGS",
                          },
                          {
                            label: "Any product could be reduced",
                            value: "CAN_REDUCE_FACINGS",
                          },
                          {
                            label: "Any non-core product could be reduced / deleted",
                            value: "CAN_DELETE_RANGED_PRODUCTS",
                          },
                          {
                            label: "Any product could be reduced / deleted",
                            value: "CAN_DELETE_RANGED_PRODUCTS_INCLUDING_CORE_RANGE",
                          },
                        ]}
                        isDisabled={!data.row.isChecked}
                      />
                    ),
                  },
                ]}
                data={Object.values(filteredSelectableProducts)}
              />
            </Scroller>
          </Flex>
        )}
      </Flex>

      <Flex justify="between" gap="10px">
        <Button
          color={isModified ? "red" : "greenSmoke"}
          variant="inverted"
          onClick={() => setStep("STEP_3_PRODUCT_REVIEW")}
        >
          {isModified ? "Cancel" : "Back"}
        </Button>

        <Button isDisabled={noSelected || hasPartiallySelected} onClick={onSave}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
