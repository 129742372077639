import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { chunk } from "lodash";
import { Box, Color, Flex, SubgridTable } from "src/elements";
import { SubgridCell } from "src/elements/SubgridTable/components/SubgridCell";
import { SubgridRow } from "src/elements/SubgridTable/components/SubgridRow";
import { SubgridColumn } from "src/elements/SubgridTable/store/types";
import styled from "styled-components";

type NotchRow = { shelfNo: number; notchNo: number; type: "SHELF" | "BAY"; bayNo: number };

const CustomRow = styled(SubgridRow)`
  background-color: ${Color.greenSmoke};
  font-size: 10px;
`;

const columns: SubgridColumn<NotchRow>[] = [
  {
    id: "shelf",
    header: "Shelf",
  },
  {
    id: "notchNo",
    header: "Notch",
  },
];

export const NotchTable = ({ data }: { data: NotchRow[] }) => {
  return (
    <SubgridTable
      columns={columns}
      data={data}
      rowRenderer={(row) =>
        row.type === "SHELF" ? (
          <CustomRow>
            <SubgridCell>
              {row.bayNo}.{row.shelfNo}
            </SubgridCell>
            <SubgridCell>{row.notchNo}</SubgridCell>
          </CustomRow>
        ) : (
          <div style={{ display: "block", fontWeight: 600, background: Color.greenSmokeActive }}>
            Bay: {row.bayNo}
          </div>
        )
      }
    />
  );
};

const processChunkNotchRow = (totalNotchRow: NotchRow[]) => {
  let chunks: NotchRow[][] = [];
  chunks = chunk(totalNotchRow, 25);

  chunks.forEach((chunk) => {
    if (chunk[0]?.type === "SHELF") {
      chunk.unshift({ shelfNo: 0, notchNo: 0, type: "BAY", bayNo: chunk[0].bayNo });
    }
    if (chunk.at(-1)?.type === "BAY") {
      chunk.pop();
    }
  });
  return chunks;
};

export const NotchPage = ({ pog }: { pog: Pog }) => {
  const totalNotchRow: NotchRow[] = [];
  pog.planogram.bays.forEach((bay) => {
    totalNotchRow.push({ shelfNo: 0, notchNo: 0, type: "BAY", bayNo: bay.bayNo });
    bay.shelves.forEach((shelf) => {
      totalNotchRow.push({
        shelfNo: shelf.shelfNo,
        notchNo: shelf.notchNo,
        type: "SHELF",
        bayNo: bay.bayNo,
      });
    });
  });

  const chunked = processChunkNotchRow(totalNotchRow);

  return (
    <Flex direction="row" flexWrap="wrap" justify="left" gap="16px">
      {chunked.map((chunk, index) => (
        <Box key={index} width="20%" padding="8px 0 0 0">
          <NotchTable key={index} data={chunk} />
        </Box>
      ))}
    </Flex>
  );
};
