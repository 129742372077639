import { Highlight } from "@CommonApi/merchflows/_merchflowId_/highlights/base-pogs";
import { PlanogramItem, Pog } from "@CommonTypes/merchflow/pog/pog";
import {
  getPlanogramItemCapacity,
  getPlanogramItemDaysOfSupply,
  getPlanogramItemMOSPercentage,
} from "@CommonUtils/pog/item";
import { PlanogramLegend } from "src/components/PlanogramExtensions/PlanogramLegend";
import { higlightsSpecialFieldsFormulas } from "src/modals/Highlight/store/utils";

export const getComboValue = (
  productCode: string,
  pog: Pog,
  method: (item: PlanogramItem) => number,
) => {
  let value = 0;

  for (const bay of pog.planogram.bays) {
    for (const shelf of bay.shelves) {
      for (const item of shelf.items) {
        if (item.productCode === productCode) {
          value += method(item);
        }
      }
    }
  }

  return value;
};

export const getHighlightColor = (
  highlight: Highlight,
  item: PlanogramItem,
  pog: Pog,
  planogramLegend: PlanogramLegend,
) => {
  // @ts-ignore
  if (
    higlightsSpecialFieldsFormulas.includes(highlight.formula.trim()) &&
    // @ts-ignore
    planogramLegend.formulaColorsMap[item[highlight.formula.trim()]]
  ) {
    // @ts-ignore
    return planogramLegend.formulaColorsMap[item[highlight.formula.trim()]];
  }

  let formula = "";

  const escapedWords = higlightsSpecialFieldsFormulas.map((word) =>
    word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
  );
  const pattern = new RegExp(`(${escapedWords.join("|")})`, "g");
  const parts = highlight.formula.split(pattern);

  parts.forEach((part) => {
    if (higlightsSpecialFieldsFormulas.includes(part)) {
      if (part === "mos") {
        formula += getPlanogramItemMOSPercentage(item);
      } else if (part === "minOnShelf") {
        formula += "100";
      } else if (part === "dos") {
        formula += getComboValue(item.productCode, pog, getPlanogramItemDaysOfSupply);
      } else if (part === "capacity") {
        formula += getComboValue(item.productCode, pog, getPlanogramItemCapacity);
        //@ts-ignore
      } else if (typeof item[part] === "string") {
        //@ts-ignore
        formula += `"${item[part]}"`;
      } else {
        //@ts-ignore
        formula += item[part];
      }
    } else {
      formula += part;
    }
  });

  try {
    // eslint-disable-next-line no-eval
    if (eval(formula) === true) {
      if (
        higlightsSpecialFieldsFormulas.includes(highlight.formula.trim()) &&
        planogramLegend.formulaColorsMap[formula]
      ) {
        return planogramLegend.formulaColorsMap[formula];
      }

      return highlight.color;
    }
  } catch {
    console.warn(`Highlight ${highlight.name} formula ${highlight.formula} is invalid`);
  }

  return false;
};
