import { PlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { useSetAtom } from "jotai";

import { PogAtom } from "./types";
import { findPogShelf, removeItemFromPlanogramAndUnrangedItems } from "./utils";
import { adapterPogNonCircularToPog, adapterPogToPogNonCircular } from "@CommonAdapters/pog";

export const usePOG = (pogAtom: PogAtom) => {
  const setPog = useSetAtom(pogAtom);

  const dropItemNextToAnotherItem = ({
    uniqueId,
    side,
    item,
  }: {
    uniqueId: string;
    side: "left" | "right";
    item: PlanogramItem;
  }) => {
    setPog((pog) => {
      removeItemFromPlanogramAndUnrangedItems({ pog, itemId: item.uniqueId });

      // Add item next to droppable item.
      for (const bay of pog.planogram.bays) {
        for (const shelf of bay.shelves) {
          const droppableItemIndex = shelf.items.findIndex((i) => i.uniqueId === uniqueId);
          if (droppableItemIndex !== -1) {
            item.dndFlag = true;
            item.shelf = shelf;

            shelf.items.splice(droppableItemIndex + (side === "right" ? 1 : 0), 0, item);
          }
        }
      }

      return { ...pog };
    });
  };

  const dropItemToShelf = ({ shelfId, item }: { shelfId: string; item: PlanogramItem }) => {
    setPog((pog) => {
      removeItemFromPlanogramAndUnrangedItems({ pog, itemId: item.uniqueId });

      // Find the most right of merged shelf.
      let shelf = findPogShelf({ pog, shelfId });

      while (shelf && shelf.mergedRight) {
        shelf = findPogShelf({ pog, shelfId: shelf.mergedRight.uniqueId });
      }

      if (shelf) {
        item.shelf = shelf;
        shelf.items.push(item);
      }

      return { ...pog };
    });
  };

  const moveProductToUnrangedItems = (item: PlanogramItem) => {
    setPog((pog) => {
      const pogNonCircular = adapterPogToPogNonCircular(pog);

      // Remove multiple variants of same product from the plangoram.
      for (const bay of pogNonCircular.planogram.bays) {
        for (const shelf of bay.shelves) {
          // We assign itemToUnrange inside the filter loop for performance.
          // eslint-disable-next-line no-loop-func
          shelf.items = shelf.items.filter((_item) => _item.productCode !== item.productCode);
        }
      }

      // Move item to unranged items.
      pogNonCircular.unrangedItems.splice(0, 0, {
        ...item,
        dndFlag: true,
        shelf: null,
        facingsRows: 1,
        facings: 1,
        potentialShelves: item.shelf ? [item.shelf.uniqueId] : [],
      });

      return adapterPogNonCircularToPog(pogNonCircular);
    });
  };

  const savePogChanges = () => {
    setPog((pog) => ({ ...pog }));
  };

  const deleteProduct = ({ item, storeCodes }: { item: PlanogramItem; storeCodes: string[] }) => {
    setPog((pog) => {
      // Remove product from planogram.
      for (const bay of pog.planogram.bays) {
        for (const shelf of bay.shelves) {
          shelf.items = shelf.items.filter((_item) => _item.productCode !== item.productCode);
        }
      }

      // Remove product from unranged items.
      pog.unrangedItems = pog.unrangedItems.filter(
        (_item) => _item.productCode !== item.productCode,
      );

      // Add item to the list of deleted items.
      pog.deletedItems = pog.deletedItems
        ? [...pog.deletedItems, { ...item, storeCodes }]
        : [{ ...item, storeCodes }];

      return { ...pog };
    });
  };

  return {
    dropItemNextToAnotherItem,
    dropItemToShelf,
    moveProductToUnrangedItems,
    savePogChanges,
    deleteProduct,
  };
};
