import { Pog } from "@CommonTypes/merchflow/pog/pog";
import { PrimitiveAtom, useAtomValue } from "jotai";
import { noop } from "lodash";
import { Bays } from "src/components/Planogram/components/Bay/Bays";
import { Shelves } from "src/components/Planogram/components/Shelf/Shelves";
import { PlanogramContainer, PlanogramElement } from "./PdfPlanogramPage";

export const FirstPdfPage = ({
  pogAtom,
  scaleX,
  scaleY,
}: {
  pogAtom: PrimitiveAtom<Pog>;
  scaleX: number;
  scaleY: number;
}) => {
  const pog = useAtomValue(pogAtom);
  return (
    <PlanogramContainer isRenderingAsImage={false}>
      {scaleX && scaleY && (
        <PlanogramElement
          width={pog.planogram.width}
          height={pog.planogram.height}
          scaleX={scaleX}
          scaleY={scaleY}
        >
          <Bays
            bays={pog.planogram.bays}
            scaleX={scaleX}
            scaleY={scaleY}
            isEditable={false}
            pogAtom={pogAtom}
          />

          <Shelves
            scaleX={scaleX}
            scaleY={scaleY}
            isEditable={false}
            isPog={true}
            highlightedItems={[]}
            pogAtom={pogAtom}
            setIsModified={noop}
            isDuplicateDisabled={true}
            availableStores={null}
            pdfMode
          />
        </PlanogramElement>
      )}
    </PlanogramContainer>
  );
};
