import { useRef } from "react";
import { Flex } from "src/elements/Box/Box";
import { Color } from "src/elements/Color/Color";
import { Text } from "src/elements/Text/Text";
import { useHoverClickable } from "src/utils/hoverClickable";
import styled from "styled-components";

const TabElement = styled(Flex)`
  flex: 1;
  padding: 10px;
  justify-content: center;
`;

interface Props<T> {
  label: string;
  value: T;
  setValue: (value: T) => void;
  isActive: boolean;
  isDisabled?: boolean;
}

export const Tab = <T,>({ label, value, setValue, isActive, isDisabled }: Props<T>) => {
  const ref = useRef(null);
  const { isHovered } = useHoverClickable(ref);

  return (
    <TabElement
      ref={ref}
      background={
        (isDisabled && isActive && Color.lightGrayHover) ||
        (isDisabled && Color.lightGray) ||
        (isActive && Color.primary) ||
        (isHovered && Color.greenSmokeHover) ||
        Color.greenSmoke
      }
      onClick={
        !isDisabled
          ? () => {
              setValue(value);
            }
          : undefined
      }
    >
      <Text
        color={
          (isDisabled && isActive && Color.spaceGray) ||
          (isDisabled && Color.textDisabled) ||
          (isActive && Color.white) ||
          Color.primaryActive
        }
        textAlign="center"
      >
        {label}
      </Text>
    </TabElement>
  );
};
