import hexToRgba from "hex-to-rgba";
import { useRef } from "react";
import { Key } from "src/utils/keys";
import styled from "styled-components";

import { Color, Flex, Icon, Text } from "src/elements";
import { useHoverClickable } from "src/utils/hoverClickable";
import { MenuOption } from "../store/types";

const OptionElement = styled.div<{ isHovered: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;

  background-color: ${({ isHovered }) => isHovered && Color.white};
`;

const OptionKeybind = styled.div<{ isHovered: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${({ isHovered }) =>
    isHovered ? hexToRgba(Color.textMain, 0.1) : hexToRgba(Color.white, 0.2)};
  padding: 1px 3px;
  border-radius: 2px;
  gap: 3px;
`;

interface Props {
  option: MenuOption;
  isSelected: boolean;
}

export const Option = ({ option: { label, keybind, icon, onClick }, isSelected }: Props) => {
  const refOption = useRef<HTMLDivElement>(null);
  const { isHovered } = useHoverClickable(refOption);

  const isActive = isHovered || isSelected;

  const preKey = `
    ${keybind?.ctrl ? "CTRL + " : ""}
    ${keybind?.key === Key.DELETE ? "Del" : ""}
    ${keybind?.key === Key.N ? "N" : ""}
    ${keybind?.key === Key.D ? "D" : ""}
    ${keybind?.key === Key.U ? "U" : ""}
  `.trim();

  return (
    <OptionElement ref={refOption} isHovered={isActive} onClick={onClick}>
      <Flex gap="10px" align="center">
        {icon && <Icon name={icon.name} size={14} color={icon.color} />}

        <Text variant="caption1" color={(isActive && Color.textMain) || Color.white}>
          {label}
        </Text>
      </Flex>

      {keybind && (
        <OptionKeybind isHovered={isActive}>
          {preKey && (
            <Text variant="caption1" color={(isActive && Color.textMain) || Color.white}>
              {preKey}
            </Text>
          )}

          <Icon
            name={
              (keybind.key === Key.UP && "arrowUp") ||
              (keybind.key === Key.DOWN && "arrowDown") ||
              (keybind.key === Key.LEFT && "arrowLeft") ||
              (keybind.key === Key.RIGHT && "arrowRight")
            }
            size={12}
            color={(isActive && Color.textMain) || Color.white}
          />
        </OptionKeybind>
      )}
    </OptionElement>
  );
};
