import hexToRgba from "hex-to-rgba";
import { ReactNode } from "react";
import { Color, Flex, Text } from "src/elements";
import { AnchorLayer } from "src/elements/AnchorLayer/AnchorLayer";
import { useAnchorLayer } from "src/elements/AnchorLayer/store/hooks";
import { AnchorLayerPosition } from "src/elements/AnchorLayer/store/types";
import { TextProps } from "src/elements/Text/Text";
import styled from "styled-components";

const tooltipColor = hexToRgba(Color.textMain, 0.7);
const tooltipBlur = "blur(3px)";
const tipSize = "5px";

const TooltipElement = styled(Flex)`
  padding: 10px;
  background-color: ${tooltipColor};
  backdrop-filter: ${tooltipBlur};
  border-radius: 3px;
  padding: 10px;
  user-select: text;
  cursor: default;
`;

const TooltipArrow = styled(Flex)<{
  arrowPosition?: AnchorLayerPosition;
  compensation?: number;
}>`
  position: absolute;
  top: ${({ arrowPosition }) =>
    (arrowPosition === "bottom" && `-${tipSize}`) ||
    ((arrowPosition === "left" || arrowPosition === "right") && "0") ||
    undefined};
  left: ${({ arrowPosition }) =>
    (arrowPosition === "right" && `-${tipSize}`) ||
    ((arrowPosition === "top" || arrowPosition === "bottom") && "0") ||
    undefined};
  right: ${({ arrowPosition }) =>
    (arrowPosition === "left" && `-${tipSize}`) ||
    ((arrowPosition === "top" || arrowPosition === "bottom") && "0") ||
    undefined};
  bottom: ${({ arrowPosition }) =>
    (arrowPosition === "top" && `-${tipSize}`) ||
    ((arrowPosition === "left" || arrowPosition === "right") && "0") ||
    undefined};
  margin: auto;
  width: 0;
  height: 0;
  backdrop-filter: ${tooltipBlur};
  border-left: ${({ arrowPosition }) =>
    (arrowPosition === "left" && `${tipSize} solid ${tooltipColor}`) ||
    ((arrowPosition === "bottom" || arrowPosition === "top") && `${tipSize} solid transparent`) ||
    undefined};
  border-right: ${({ arrowPosition }) =>
    (arrowPosition === "right" && `${tipSize} solid ${tooltipColor}`) ||
    ((arrowPosition === "bottom" || arrowPosition === "top") && `${tipSize} solid transparent`) ||
    undefined};
  border-bottom: ${({ arrowPosition }) =>
    (arrowPosition === "bottom" && `${tipSize} solid ${tooltipColor}`) ||
    ((arrowPosition === "left" || arrowPosition === "right") && `${tipSize} solid transparent`) ||
    undefined};
  border-top: ${({ arrowPosition }) =>
    (arrowPosition === "top" && `${tipSize} solid ${tooltipColor}`) ||
    ((arrowPosition === "left" || arrowPosition === "right") && `${tipSize} solid transparent`) ||
    undefined};
  transform: ${({ compensation, arrowPosition }) =>
    (compensation &&
      (arrowPosition === "top" || arrowPosition === "bottom") &&
      `translateX(${compensation}px)`) ||
    (compensation &&
      (arrowPosition === "left" || arrowPosition === "right") &&
      `translateY(${compensation}px)`)};
`;

interface Props {
  position?: AnchorLayerPosition;
  width?: string;
  textAlign?: TextProps["textAlign"];
  whiteSpace?: TextProps["whiteSpace"];
  isClickable?: boolean;
  children: ReactNode;
}

export const Tooltip = ({
  position = "bottom",
  width,
  textAlign = "center",
  whiteSpace = "nowrap",
  isClickable = false,
  children,
}: Props) => {
  const { refTrigger, refLayer, isRendering, layerState, hideAnchorLayer } = useAnchorLayer({
    isUsingHover: !isClickable,
    isUsingClick: isClickable,
    isCentered: true,
    isUsingParent: true,
    position,
    offset: 10,
  });

  return (
    <AnchorLayer
      refTrigger={refTrigger}
      refLayer={refLayer}
      isRendering={isRendering}
      layerState={layerState}
      hideAnchorLayer={hideAnchorLayer}
      isUsingClick={isClickable}
    >
      <TooltipElement width={width} onClick={(event) => event.stopPropagation()}>
        <TooltipArrow
          arrowPosition={layerState?.position}
          compensation={layerState?.compensation}
        />

        <Text variant="small2" color={Color.white} textAlign={textAlign} whiteSpace={whiteSpace}>
          {children}
        </Text>
      </TooltipElement>
    </AnchorLayer>
  );
};
