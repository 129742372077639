import { PspRules } from "@CommonTypes/backend/PspRules";
import { RulesSection } from "./components/RulesSection";
import { Grid } from "src/elements";

interface Props {
  pspRules: PspRules;
  setPspRules: (pspRules: PspRules) => void;
  isDisabled?: boolean;
}

export const UhdPlanogramRules = ({ pspRules, setPspRules, isDisabled: _isDisabled }: Props) => {
  const isDisabled = _isDisabled || pspRules.high_density_mode.enabled === false;

  return (
    <Grid
      width="100%"
      gap="20px"
      areas={`
        "high-density high-density"
      `}
    >
      <RulesSection
        pspRules={pspRules}
        setPspRules={setPspRules}
        title="High Density Mode"
        rulesParent="high_density_mode"
        area="high-density"
        sectionRule="enabled"
        isDisabled={_isDisabled}
        rules={[
          {
            title:
              "When variants occupy more than 1 bay, continue ranging products in the next bay from?",
            rule: "snake_on_variant_cross",
            isDisabled,
          },
          {
            title:
              "When there are variant restrictions on shelves, can we round off variants on previous shelves?",
            rule: "variant_contiguous",
            isDisabled,
          },
          {
            title: "Should we place all variants on the mudmap?",
            rule: "place_all_variants",
            isDisabled,
          },
          {
            title: "Allow breaking variant sequencing?",
            rule: "allow_breaking_variant_sequencing",
            isDisabled,
          },
          {
            title: "Mudmap level",
            rule: "mudmap_level",
            isDisabled,
            options: ["CDT1", "CDT2", "ITEM_ID"],
          },
        ]}
      />
    </Grid>
  );
};
