import { PspRules } from "@CommonTypes/backend/PspRules";

export const defaultPspRules: PspRules = {
  fixture: {
    can_adjust_shelf_notches: true,
    can_add_or_delete_shelves: false,
    shelves_same_height_as_base_pog: true,
    vertical_overlap: false,
  },
  range: {
    can_add_non_core_range_products: true,
  },
  merchandising: {
    can_switch_between_horizontal_and_vertical_blocking: false,
    can_break_cases_trays_if_core_range_not_fit: true,
    can_fill_extra_space_next_to_cases_trays_with_loose_products: false,
    how_should_loose_products_be_placed_with_respect_to_their_cases_trays: "BEHIND",
    leverage_alternative_orientations: true,
    snaking: false,
    cdt_stacking: true,
  },
  high_density_mode: {
    enabled: false,
    snake_on_variant_cross: true,
    variant_contiguous: true,
    place_all_variants: false,
    allow_breaking_variant_sequencing: false,
    mudmap_level: "CDT2",
  },
  optimize_on: "SALES",
  relax_factors: {
    either_or: 1,
    both_or_none: 1,
    pre_req: 1,
    shelf_item_count: 1,
    fixed_notch_num: 0.2,
    finger_space: 5,
    units_not_enough_dos: 0,
    units_not_enough_mos: 0,
    units_too_many: 0,
    facings_not_enough: 0,
    facings_too_many: 10,
    min_width: 0.5,
    min_distribution: 1,
    width_slack: 0.1,
    cdt1_contiguous: 0,
    cdt2_contiguous: 1,
    cdt3_contiguous: 1,
    cdt1_order: 0,
    cdt2_order: 1,
    cdt3_order: 1,
    item_id_order: 0.1,
    item_span: 0.1,
    cdt1_span: 0,
    cdt2_span: 0,
    cdt3_span: 0.2,
    secondary_orientation: 0.3,
  },
};
