import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useModals } from "src/components/Modals";
import { Button, Flex, HorizontalDivider, Modal, Scroller, Tabs, Toggle } from "src/elements";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { pages } from "src/utils";
import { useNavigation } from "src/utils/navigation";
import { storePlanogramRulesDataAtom } from "./store/atoms";
import { useApi } from "src/api";
import { routeToUrl } from "src/utils/axios";
import { PlanogramOptimizeOn } from "src/components";
import { PlanogramOptimizeOnProps } from "src/components/PlanogramOptimizeOn/PlanogramOptimizeOn";
import { GeneralPlanogramRules } from "src/components/PlanogramRules/GeneralPlanogramRules";
import { UhdPlanogramRules } from "src/components/PlanogramRules/UhdPlanogramRules";
import { OptimizationOptionsRules } from "src/components/PlanogramRules/OptimizationOptionsRules";

interface Props {
  merchflowId: number;
  basePogId: number;
  isBasePogFavorited: boolean;
}

export const ModalStorePlanogramRules = () => {
  const [data, setData] = useAtom(storePlanogramRulesDataAtom);

  useEffect(() => {
    return () => {
      setData(null);
    };
  }, []);

  if (!data) return null;

  return (
    <ModalStorePlanogramRulesInner
      merchflowId={data.merchflowId}
      basePogId={data.basePogId}
      isBasePogFavorited={data.isBasePogFavorited}
    />
  );
};

const ModalStorePlanogramRulesInner = ({ merchflowId, basePogId, isBasePogFavorited }: Props) => {
  const { closeModal: _closeModal, openConfirmModal } = useModals();
  const { navigate } = useNavigation();
  const {
    putMerchflowBasePog_basePogId_StatusApi,
    postMerchflowStartStorePlanogramsGenerationApi,
    postMerchflowStartStorePlanogramsCopyToStoresGenerationApi,
    getMerchflowStoresAllApi,
  } = useApi();

  const [copyToStores, setCopyToStores] = useState(false);
  const [tab, setTab] = useState<"GENERAL" | "UHD" | "OPTIMIZATION">("GENERAL");
  const [pspRules, setPspRules] = useState(defaultPspRules);
  const [optimizeOn, setOptimizeOn] = useState<PlanogramOptimizeOnProps["optimizeOn"]>("SALES");

  const refEitherOr = useRef(defaultPspRules.relax_factors.either_or);
  const refBothOrNone = useRef(defaultPspRules.relax_factors.both_or_none);
  const refPreReq = useRef(defaultPspRules.relax_factors.pre_req);
  const refShelfItemCount = useRef(defaultPspRules.relax_factors.shelf_item_count);
  const refFixedNotchNum = useRef(defaultPspRules.relax_factors.fixed_notch_num);
  const refFingerSpace = useRef(defaultPspRules.relax_factors.finger_space);
  const refUnitsNotEnoughDos = useRef(defaultPspRules.relax_factors.units_not_enough_dos);
  const refUnitsNotEnoughMos = useRef(defaultPspRules.relax_factors.units_not_enough_mos);
  const refUnitsTooMany = useRef(defaultPspRules.relax_factors.units_too_many);
  const refFacingsNotEnough = useRef(defaultPspRules.relax_factors.facings_not_enough);
  const refFacingsTooMany = useRef(defaultPspRules.relax_factors.facings_too_many);
  const refMinWidth = useRef(defaultPspRules.relax_factors.min_width);
  const refMinDistribution = useRef(defaultPspRules.relax_factors.min_distribution);
  const refWidthSlack = useRef(defaultPspRules.relax_factors.width_slack);
  const refCdt1Contiguous = useRef(defaultPspRules.relax_factors.cdt1_contiguous);
  const refCdt2Contiguous = useRef(defaultPspRules.relax_factors.cdt2_contiguous);
  const refCdt3Contiguous = useRef(defaultPspRules.relax_factors.cdt3_contiguous);
  const refCdt1Order = useRef(defaultPspRules.relax_factors.cdt1_order);
  const refCdt2Order = useRef(defaultPspRules.relax_factors.cdt2_order);
  const refCdt3Order = useRef(defaultPspRules.relax_factors.cdt3_order);
  const refItemIdOrder = useRef(defaultPspRules.relax_factors.item_id_order);
  const refItemSpan = useRef(defaultPspRules.relax_factors.item_span);
  const refCdt1Span = useRef(defaultPspRules.relax_factors.cdt1_span);
  const refCdt2Span = useRef(defaultPspRules.relax_factors.cdt2_span);
  const refCdt3Span = useRef(defaultPspRules.relax_factors.cdt3_span);
  const refSecondaryOrientation = useRef(defaultPspRules.relax_factors.secondary_orientation);

  const startGeneratingStorePlanograms = async () => {
    closeModal();

    const storePogTriggerId = copyToStores
      ? await postMerchflowStartStorePlanogramsCopyToStoresGenerationApi({
          base_pog_id: basePogId,
        })
      : await postMerchflowStartStorePlanogramsGenerationApi({
          base_pog_id: basePogId,
          psp_rules: {
            ...pspRules,
            optimize_on: optimizeOn,
            relax_factors: {
              either_or: refEitherOr.current,
              both_or_none: refBothOrNone.current,
              pre_req: refPreReq.current,
              shelf_item_count: refShelfItemCount.current,
              fixed_notch_num: refFixedNotchNum.current,
              finger_space: refFingerSpace.current,
              units_not_enough_dos: refUnitsNotEnoughDos.current,
              units_not_enough_mos: refUnitsNotEnoughMos.current,
              units_too_many: refUnitsTooMany.current,
              facings_not_enough: refFacingsNotEnough.current,
              facings_too_many: refFacingsTooMany.current,
              min_width: refMinWidth.current,
              min_distribution: refMinDistribution.current,
              width_slack: refWidthSlack.current,
              cdt1_contiguous: refCdt1Contiguous.current,
              cdt2_contiguous: refCdt2Contiguous.current,
              cdt3_contiguous: refCdt3Contiguous.current,
              cdt1_order: refCdt1Order.current,
              cdt2_order: refCdt2Order.current,
              cdt3_order: refCdt3Order.current,
              item_id_order: refItemIdOrder.current,
              item_span: refItemSpan.current,
              cdt1_span: refCdt1Span.current,
              cdt2_span: refCdt2Span.current,
              cdt3_span: refCdt3Span.current,
              secondary_orientation: refSecondaryOrientation.current,
            },
          },
        });

    if (!storePogTriggerId) {
      return;
    }

    let storePogData = [];
    while (storePogData.length === 0) {
      const storeAllResponse = await getMerchflowStoresAllApi(merchflowId, {
        page: 1,
        size: 8,
        store_pog_trigger_id: storePogTriggerId,
      });
      storePogData = storeAllResponse?.data || [];
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }

    navigate(
      routeToUrl(
        pages.merchflows_merchflowId_StoresAll,
        {
          merchflowId,
        },
        {
          store_pog_trigger_id: storePogTriggerId,
        },
      ),
    );
  };

  const checkFavoriteAndStartGeneratingStorePlanograms = () => {
    if (isBasePogFavorited) {
      startGeneratingStorePlanograms();
    } else {
      openConfirmModal({
        title: "Start Generating Store Planograms?",
        description:
          "Only store planograms of favourited base planograms are available for approval in the Store Review step of your flow review.",
        buttons: [
          { label: "Back", color: "primary", variant: "inverted" },
          {
            label: "Only Generate",
            color: "yellow",
            onClick: startGeneratingStorePlanograms,
          },
          {
            label: "Favorite and Generate",
            color: "primary",
            onClick: favoriteAndStartGeneratingStorePlanograms,
          },
        ],
      });
    }
  };

  const favoriteAndStartGeneratingStorePlanograms = () => {
    putMerchflowBasePog_basePogId_StatusApi({
      is_favourite: [basePogId],
    });
    startGeneratingStorePlanograms();
  };

  const closeModal = () => {
    _closeModal("ModalStorePlanogramRules");
  };

  return (
    <Modal
      title="Store Planogram Rules"
      name="ModalStorePlanogramRules"
      width="500px"
      height="700px"
    >
      <Flex minHeight="0" flexGrow={1} column gap="20px" padding="20px 10px">
        <Tabs
          tabs={[
            { label: "General", value: "GENERAL" },
            { label: "UHD Mode", value: "UHD" },
            { label: "Optimization Options", value: "OPTIMIZATION" },
          ]}
          value={tab}
          setValue={setTab as (value: string) => void}
          isDisabled={copyToStores}
        />

        <Scroller gutter="stable">
          {tab === "GENERAL" && (
            <GeneralPlanogramRules
              pspRules={pspRules}
              setPspRules={setPspRules}
              isDisabled={copyToStores}
            />
          )}

          {tab === "UHD" && (
            <UhdPlanogramRules
              pspRules={pspRules}
              setPspRules={setPspRules}
              isDisabled={copyToStores}
            />
          )}

          {tab === "OPTIMIZATION" && (
            <OptimizationOptionsRules
              isDisabled={copyToStores}
              refEitherOr={refEitherOr}
              refBothOrNone={refBothOrNone}
              refPreReq={refPreReq}
              refShelfItemCount={refShelfItemCount}
              refFixedNotchNum={refFixedNotchNum}
              refFingerSpace={refFingerSpace}
              refUnitsNotEnoughDos={refUnitsNotEnoughDos}
              refUnitsNotEnoughMos={refUnitsNotEnoughMos}
              refUnitsTooMany={refUnitsTooMany}
              refFacingsNotEnough={refFacingsNotEnough}
              refFacingsTooMany={refFacingsTooMany}
              refMinWidth={refMinWidth}
              refMinDistribution={refMinDistribution}
              refWidthSlack={refWidthSlack}
              refCdt1Contiguous={refCdt1Contiguous}
              refCdt2Contiguous={refCdt2Contiguous}
              refCdt3Contiguous={refCdt3Contiguous}
              refCdt1Order={refCdt1Order}
              refCdt2Order={refCdt2Order}
              refCdt3Order={refCdt3Order}
              refItemIdOrder={refItemIdOrder}
              refItemSpan={refItemSpan}
              refCdt1Span={refCdt1Span}
              refCdt2Span={refCdt2Span}
              refCdt3Span={refCdt3Span}
              refSecondaryOrientation={refSecondaryOrientation}
            />
          )}
        </Scroller>

        <PlanogramOptimizeOn
          optimizeOn={optimizeOn}
          setOptimizeOn={setOptimizeOn}
          isDisabled={copyToStores}
        />

        <HorizontalDivider />

        <Toggle
          label="Copy Base Planogram to Stores"
          isChecked={copyToStores}
          setIsChecked={(checked) => {
            setCopyToStores(checked);
          }}
        />

        <Flex width="100%" justify="center">
          <Button onClick={checkFavoriteAndStartGeneratingStorePlanograms}>
            Generate Store Planograms
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
