import { PlanogramShelf } from "@CommonTypes/merchflow/pog/shelf";
import { useAtomValue } from "jotai";
import React from "react";
import {
  getMergedShelves,
  getShelfXLeft,
  getShelfXRight,
  getShelfYBottom,
} from "src/omni-common/utils/pog/shelf";

import { InnerWrapper } from "../../common/InnerWrapper";
import { PogAtom, Scaleable } from "../../store/types";
import { MergedShelf } from "./MergedShelf";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { Hangcell } from "./Hangcell";

interface Props extends Scaleable {
  currentStoreCode?: string;
  pogAtom: PogAtom;
  isEditable: boolean;
  isPog: boolean;
  highlightedItems: string[];
  setIsModified: (modified: boolean) => void;
  isDuplicateDisabled?: boolean;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
  pdfMode?: boolean;
}

export const Shelves = ({
  currentStoreCode,
  scaleX,
  scaleY,
  pogAtom,
  isEditable,
  isPog,
  highlightedItems,
  setIsModified,
  isDuplicateDisabled,
  availableStores,
  pdfMode = false,
}: Props) => {
  const pog = useAtomValue(pogAtom);

  return (
    <InnerWrapper className="shelves">
      {pog.planogram.bays.map((bay, i) => (
        <React.Fragment key={i}>
          {bay.shelves.map((shelf, i) => {
            if (shelf.mergedLeft !== null) return null;

            const bottomShelf: PlanogramShelf | undefined = bay.shelves[i - 1];
            const topShelf: PlanogramShelf | undefined = bay.shelves[i + 1];
            const mergedShelves = getMergedShelves(shelf);
            if (shelf.type === "HANGCELL") {
              return (
                <Hangcell
                  key={i}
                  currentStoreCode={currentStoreCode}
                  bay={bay}
                  shelves={mergedShelves}
                  bounds={{
                    xLeft: getShelfXLeft(shelf),
                    xRight: getShelfXRight(shelf),
                    yBottom: bottomShelf
                      ? getShelfYBottom(bottomShelf) + bay.notchSpacing
                      : bay.notchOffset,
                    yTop: topShelf
                      ? getShelfYBottom(topShelf) - bay.notchSpacing
                      : bay.notchOffset + (bay.maxNotch - 1) * bay.notchSpacing,
                  }}
                  scaleX={scaleX}
                  scaleY={scaleY}
                  isEditable={isEditable}
                  isPog={isPog}
                  highlightedItems={highlightedItems}
                  pogAtom={pogAtom}
                  setIsModified={setIsModified}
                  isDuplicateDisabled={isDuplicateDisabled}
                  availableStores={availableStores}
                  pdfMode={pdfMode}
                />
              );
            } else {
              return (
                <MergedShelf
                  key={i}
                  currentStoreCode={currentStoreCode}
                  bay={bay}
                  shelves={mergedShelves}
                  bounds={{
                    xLeft: getShelfXLeft(shelf),
                    xRight: getShelfXRight(shelf),
                    yBottom: bottomShelf
                      ? getShelfYBottom(bottomShelf) + bay.notchSpacing
                      : bay.notchOffset,
                    yTop: topShelf
                      ? getShelfYBottom(topShelf) - bay.notchSpacing
                      : bay.notchOffset + (bay.maxNotch - 1) * bay.notchSpacing,
                  }}
                  scaleX={scaleX}
                  scaleY={scaleY}
                  isEditable={isEditable}
                  isPog={isPog}
                  highlightedItems={highlightedItems}
                  pogAtom={pogAtom}
                  setIsModified={setIsModified}
                  isDuplicateDisabled={isDuplicateDisabled}
                  availableStores={availableStores}
                  pdfMode={pdfMode}
                />
              );
            }
          })}
        </React.Fragment>
      ))}
    </InnerWrapper>
  );
};
