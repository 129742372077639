import { PlanogramItem, Pog, UnrangedPlanogramItem } from "@CommonTypes/merchflow/pog/pog";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { highlightsModeAtom } from "src/components/Planogram/store/atoms";
import { PogAtom } from "src/components/Planogram/store/types";
import { findPogShelf, isPlanogramItemMatching } from "src/components/Planogram/store/utils";
import { Button, Color, Flex, Scroller, Search, Text } from "src/elements";

import styled from "styled-components";

import { Dropper } from "../../../Item/Dropper";
import { onDropOverToUnrangedItems } from "../../../Item/store/utils";
import { UnrangedItem } from "./components/UnrangedItem";
import { usePogNewProductModal } from "src/modals/PogNewProduct/store/hooks";
import { ResponseMerchFlowApplyFilters } from "@CommonApi/merchandise";
import { useInlineLoaders } from "src/components/InlineLoader";
import { RouteGetMerchflowsReviewFlows_merchflowId_ } from "@CommonApi/merchflows/review/flows/_merchflowId_";
import { usePOG } from "src/components/Planogram/store/actions";
import { Filterable } from "src/components/Filterable/Filterable";
import { unrangedShelfIdAtom } from "./store/atoms";

type ViewFilter = "ALL" | "NEW" | "CORE_RANGE" | "UNRANGED_SHELF";

export type UnrangedItemSortFilter = "minOnShelf" | "quantity" | "salesAmount" | "potentialShelf";

const sortByOptions: { label: string; value: UnrangedItemSortFilter }[] = [
  { label: "MOS", value: "minOnShelf" },
  { label: "Movement", value: "quantity" },
  { label: "Sales", value: "salesAmount" },
  { label: "Shelf", value: "potentialShelf" },
];

const viewByOptions: { label: string; value: ViewFilter }[] = [
  { label: "All", value: "ALL" },
  { label: "New", value: "NEW" },
  { label: "Core Range", value: "CORE_RANGE" },
];

const UnrangedItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 10px;
  flex-grow: 1;
  position: relative;

  // Render draggable item placeholder as unranged item placeholder.
  [data-placeholder] {
    height: 52px;
    max-height: 52px;
    width: 100%;

    .item-dropper-container {
      display: none;
    }

    .planogram-item {
      height: 100%;
      width: 100%;
      box-shadow: none;

      > * {
        display: none;
      }

      ::after {
        display: flex;
      }
    }
  }
`;

interface Props {
  merchflowId?: number;
  currentStoreCode?: string;
  scaleX: number;
  scaleY: number;
  pogAtom: PogAtom;
  availableStores: ResponseMerchFlowApplyFilters["filter_config"] | null;
}

export const UnrangedItems = ({
  merchflowId,
  currentStoreCode,
  scaleX,
  scaleY,
  pogAtom,
  availableStores,
}: Props) => {
  const { openPogNewProductModal } = usePogNewProductModal();
  const { isInlineLoading } = useInlineLoaders();
  const { moveProductToUnrangedItems } = usePOG(pogAtom);

  const [pog] = useAtom(pogAtom);
  const [highlightsMode] = useAtom(highlightsModeAtom);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<ViewFilter>("ALL");
  const [sortBy, setSortBy] = useState<UnrangedItemSortFilter>("quantity");
  const [sortByDirection, setSortByDirection] = useState<"ASCENDING" | "DESCENDING">("DESCENDING");
  const [unrangedShelfId, setUnrangedShelfId] = useAtom(unrangedShelfIdAtom);

  useEffect(() => {
    if (unrangedShelfId) {
      setFilter("UNRANGED_SHELF");
    }
  }, [unrangedShelfId]);

  const refUnrangedItems = useRef<HTMLDivElement>(null);
  const isAvailableStoresLoading = isInlineLoading(RouteGetMerchflowsReviewFlows_merchflowId_);

  const filteredAndSortedUnrangedItems = useMemo(
    () =>
      filterAndSortUnrangedItems({ pog, search, filter, sortBy, sortByDirection, unrangedShelfId }),
    [pog, search, filter, sortBy, sortByDirection],
  );

  const minimumRecommendedQuantities = useMemo(() => {
    const recommendedQuantities: { [key: string]: number } = {};

    for (const bay of pog.planogram.bays) {
      for (const shelf of bay.shelves) {
        for (const item of shelf.items) {
          if (item.variant === "") continue;

          if (
            recommendedQuantities[item.variant] === undefined ||
            item.quantity < recommendedQuantities[item.variant]
          ) {
            recommendedQuantities[item.variant] = item.quantity;
          }
        }
      }
    }

    return recommendedQuantities;
  }, [pog]);

  const onDropOver = () => {
    onDropOverToUnrangedItems({
      refUnrangedItems,
      onDrop,
    });
  };

  const onDrop = (item: PlanogramItem) => {
    moveProductToUnrangedItems(item);
  };

  const openNewProductModal = () => {
    if (availableStores && currentStoreCode) {
      openPogNewProductModal({ currentStoreCode, pog, availableStores });
    }
  };

  const getShelfLabel = (shelfId: string) => {
    const shelf = findPogShelf({ pog, shelfId });
    if (shelf) {
      return `Shelf: ${shelf.bay.bayNo}.${shelf.shelfNo}`;
    }

    return "Others";
  };

  return (
    <>
      <Flex width="100%" column gap="10px" flexGrow={1} minHeight="0">
        <Flex padding="10px 10px 0 10px">
          <Search search={search} setSearch={setSearch} />
        </Flex>

        <Flex padding="0 10px" gap="5px 10px" flexWrap="wrap">
          <Filterable
            label="Items"
            options={
              [
                ...viewByOptions,
                ...((unrangedShelfId
                  ? [{ label: "Unranged Shelf", value: "UNRANGED_SHELF" }]
                  : []) satisfies { label: string; value: ViewFilter }[]),
              ] satisfies { label: string; value: ViewFilter }[]
            }
            value={filter}
            setValue={setFilter}
            onClear={() => {
              setFilter("ALL");
              setUnrangedShelfId(null);
            }}
          />

          <Filterable
            label="Sort by"
            direction={sortByDirection}
            setDirection={setSortByDirection}
            options={sortByOptions}
            value={sortBy}
            setValue={setSortBy}
          />
        </Flex>

        <Scroller gutter="stable">
          <UnrangedItemsContent ref={refUnrangedItems} className="unranged-items">
            <Dropper
              side="left"
              width="100%"
              height="100%"
              onDropOver={onDropOver}
              onDrop={onDrop}
            />

            {filteredAndSortedUnrangedItems.map((unrangedItem, i) => {
              const isRecommended =
                minimumRecommendedQuantities[unrangedItem.variant] !== undefined &&
                unrangedItem.quantity > minimumRecommendedQuantities[unrangedItem.variant];

              return (
                <React.Fragment key={i}>
                  {sortBy === "potentialShelf" &&
                    (i === 0 ||
                      filteredAndSortedUnrangedItems[i - 1].potentialShelf !==
                        unrangedItem.potentialShelf) && (
                      <Text variant="small1" color={Color.primaryActive}>
                        {getShelfLabel(unrangedItem.potentialShelf)}
                      </Text>
                    )}

                  <UnrangedItem
                    item={unrangedItem}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    isRecommended={isRecommended}
                    extraInformation={sortBy}
                    pogAtom={pogAtom}
                    availableStores={availableStores}
                  />
                </React.Fragment>
              );
            })}

            {filteredAndSortedUnrangedItems.length === 0 && (
              <Flex width="100%" flexGrow={1} justify="center" align="center">
                <Text variant="h4" color={Color.spaceGray}>
                  No Unranged Items
                </Text>
              </Flex>
            )}
          </UnrangedItemsContent>
        </Scroller>

        <Flex className="add-new-product" width="100%" padding="0 10px 10px 10px">
          <Button
            width="100%"
            color="greenSmoke"
            onClick={openNewProductModal}
            isDisabled={highlightsMode || !merchflowId || !currentStoreCode}
            isLoading={isAvailableStoresLoading}
          >
            Add New Product
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

type FilterAndSortParams = {
  pog: Pog;
  search: string;
  filter: ViewFilter;
  sortBy: UnrangedItemSortFilter;
  sortByDirection: "ASCENDING" | "DESCENDING";
  unrangedShelfId: string | null;
};

export const filterAndSortUnrangedItems = ({
  pog,
  search,
  filter,
  sortBy,
  sortByDirection,
  unrangedShelfId,
}: FilterAndSortParams) => {
  const seen: string[] = [];

  const filteredAndTransformedItems = pog.unrangedItems.reduce(
    (
      unrangedItems: (UnrangedPlanogramItem & { potentialShelf: string })[],
      unrangedItemWithPotentialShelves,
    ) => {
      // Expand potential shelves or assign "Others"
      const potentialShelves =
        unrangedItemWithPotentialShelves.potentialShelves.length > 0
          ? unrangedItemWithPotentialShelves.potentialShelves
          : ["Others"];

      for (const potentialShelf of potentialShelves) {
        const unrangedItem = { ...unrangedItemWithPotentialShelves, potentialShelf };

        // Filter logic
        if (sortBy !== "potentialShelf" && seen.includes(unrangedItem.productCode)) {
          continue; // Skip duplicates unless sorting by potential shelf
        }
        if (sortBy !== "potentialShelf") {
          seen.push(unrangedItem.productCode);
        }
        if (!isPlanogramItemMatching(unrangedItem, search)) {
          continue; // Skip items not matching search
        }
        if (
          (filter === "NEW" && unrangedItem.newItemFlag !== true) ||
          (filter === "CORE_RANGE" && unrangedItem.inCoreRange !== true) ||
          (filter === "UNRANGED_SHELF" &&
            (unrangedShelfId === null || unrangedItem.potentialShelf !== unrangedShelfId))
        ) {
          continue; // Skip based on the filter criteria
        }

        // Add item if all conditions are met
        unrangedItems.push(unrangedItem);
      }

      return unrangedItems;
    },
    [],
  );

  // Sorting logic
  return filteredAndTransformedItems.sort((item1, item2) => {
    if (sortBy === "potentialShelf") {
      const item1Shelf = findPogShelf({ pog, shelfId: item1.potentialShelf });
      const item2Shelf = findPogShelf({ pog, shelfId: item2.potentialShelf });

      if (item1Shelf && item2Shelf) {
        const shelfComparison = `${item1Shelf.bay.bayNo}.${item1Shelf.shelfNo}`.localeCompare(
          `${item2Shelf.bay.bayNo}.${item2Shelf.shelfNo}`,
        );
        return sortByDirection === "DESCENDING" ? -shelfComparison : shelfComparison;
      }

      if (!item1Shelf && !item2Shelf) return 0;
      return item1Shelf ? -1 : 1;
    }

    const comparison = item1[sortBy] < item2[sortBy] ? -1 : item1[sortBy] > item2[sortBy] ? 1 : 0;
    return sortByDirection === "DESCENDING" ? -comparison : comparison;
  });
};
