import { PlanogramContainer } from "@CommonTypes/merchflow/pog/container";
import Rand from "rand-seed";

export function getContainerColor(seed: string, usedColors: string[]): string {
  const random = new Rand(seed);
  const randomColorIndex = Math.round(random.next() * 1000);

  const colors = [
    "#F06292",
    "#EC407A",
    "#E91E63", // Pink
    "#BA68C8",
    "#9C27B0",
    "#7B1FA2", // Purple
    "#9575CD",
    "#7E57C2",
    "#5E35B1", // Deep Purple
    "#7986CB",
    "#5C6BC0",
    "#3949AB", // Indigo
    "#64B5F6",
    "#42A5F5",
    "#2196F3", // Blue
    "#4DD0E1",
    "#26C6DA",
    "#00BCD4", // Cyan
    "#4DB6AC",
    "#26A69A",
    "#009688", // Teal
    "#81C784",
    "#66BB6A",
    "#4CAF50", // Green
    "#AED581",
    "#9CCC65",
    "#8BC34A", // Light Green
    "#D4E157",
    "#CDDC39", // Lime
    "#FFB74D",
    "#FFA726",
    "#FF9800", // Orange
    "#FF8A65",
    "#F57C00", // Deep Orange
    "#A1887F",
    "#8D6E63",
    "#795548", // Brown
    "#90A4AE",
    "#78909C",
    "#607D8B", // Blue Grey
  ];

  // Try to pick color based on seed only.
  const pickedColor = colors[randomColorIndex % colors.length];

  // If color was already used, try to pick from unused colors instead.
  if (usedColors.includes(pickedColor)) {
    const unusedColors = colors.filter((color) => !usedColors.includes(color));
    if (unusedColors.length > 0) {
      return unusedColors[randomColorIndex % unusedColors.length];
    }

    if (unusedColors.length === 0) {
      return `rgb(
        ${getRandomNumberBetween(100, 200)},
        ${getRandomNumberBetween(100, 200)},
        ${getRandomNumberBetween(100, 200)}
      )`;
    }
  }

  return pickedColor;
}

const getRandomNumberBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getRandomDarkerColor = (hexColor: string, amount: number) => {
  // Convert hex color to HSL components
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  // Convert RGB to HSL
  const hsl = rgbToHsl(r, g, b);

  // Calculate the new lightness based on the given amount
  const newLightness = Math.max(0, hsl[2] - amount);

  // Convert HSL back to RGB
  const newRgb = hslToRgb(hsl[0], hsl[1], newLightness);

  // Convert the new RGB values to hex
  const newHexColor = `#${newRgb[0].toString(16).padStart(2, "0")}${newRgb[1]
    .toString(16)
    .padStart(2, "0")}${newRgb[2].toString(16).padStart(2, "0")}`;

  return newHexColor;
};

// Helper function to convert RGB to HSL
function rgbToHsl(r: number, g: number, b: number) {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s: number;

  const l = (max + min) / 2;

  if (max === min) {
    // achromatic
    h = 0;
    s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
}

// Helper function to convert HSL to RGB
function hslToRgb(h: number, s: number, l: number) {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;
  let r;
  let g;
  let b;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
}

export const algoS2FreezerItemLabelCdtsRegex =
  // @ts-ignore
  /\('(?<cdt0>.*)', '(?<cdt1>.*)', '(?<cdt2>.*)'\)/;

export const getContainerLabelDetails = (container: PlanogramContainer) => {
  const details: {
    label: string;
    cdt0?: string;
    cdt1?: string;
    cdt2?: string;
  } = {
    label: container.label,
    cdt0: undefined,
    cdt1: undefined,
    cdt2: undefined,
  };

  const cdts = algoS2FreezerItemLabelCdtsRegex.exec(container.label);

  if (cdts?.groups) {
    details.cdt0 = cdts.groups.cdt0;
    details.cdt1 = cdts.groups.cdt1;
    details.cdt2 = cdts.groups.cdt2;
  }

  return details;
};
