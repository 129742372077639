import styled from "styled-components";

import { Flex } from "src/elements/Box/Box";
import { Color } from "src/elements/Color/Color";

export const TrackElement = styled(Flex)`
  width: 100%;
  position: absolute;
  height: 6px;
  top: 6px;
  border-radius: 6px;
  pointer-events: none;
`;

const TrackBar = styled(Flex)<{ percentage?: number }>`
  width: ${({ percentage }) => `${percentage}%`};
  height: 100%;
  border-radius: 6px;

  &:last-of-type {
    flex: 1;
  }
`;

interface Props {
  percentage: number;
  isDisabled?: boolean;
}

export const Track = ({ percentage, isDisabled }: Props) => (
  <TrackElement>
    <TrackBar
      percentage={percentage}
      background={(isDisabled && Color.textDisabled) || Color.primary}
    />
    <TrackBar background={(isDisabled && Color.lightGrayHover) || Color.spaceGray} />
  </TrackElement>
);
