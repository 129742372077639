import React, { useEffect } from "react";
import { Color } from "src/elements";
import styled from "styled-components";

const InputElement = styled.input<{
  isDisabled?: boolean;
  isSoftDisabled?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
}>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 7px 10px;
  padding-left: ${({ paddingLeft }) => paddingLeft && "33px"};
  padding-right: ${({ paddingRight }) => paddingRight && "33px"};
  color: ${({ isDisabled }) => (isDisabled && Color.textDisabled) || Color.textMain};
  overflow: hidden;

  ::placeholder {
    color: ${Color.spaceGray};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px ${Color.greenSmokeDisabled} inset !important;
    border-radius: 4px;
  }

  pointer-events: ${({ isSoftDisabled }) => isSoftDisabled && "none"};
`;

interface Props<T> {
  refInput?: React.RefObject<HTMLInputElement>;
  type: "text" | "password" | "number";
  name: string;
  value: T;
  setValue: (value: T) => void;
  placeholder?: string;
  min?: string | number;
  max?: string | number;
  isDisabled?: boolean;
  isSoftDisabled?: boolean;
  isFocusedAutomatically?: boolean;
  isSpellchecked?: boolean;
  paddingLeft?: boolean;
  paddingRight?: boolean;
}

export const InnerInput = <T extends string | number>({
  refInput,
  type,
  placeholder,
  min,
  max,
  value,
  setValue,
  isDisabled,
  isSoftDisabled,
  isFocusedAutomatically,
  isSpellchecked,
  paddingLeft,
  paddingRight,
  name,
}: Props<T>) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof value === "number") {
      setValue(Number(event.target.value) as T);
    } else {
      setValue(event.target.value as T);
    }
  };

  useEffect(() => {
    if (isFocusedAutomatically) {
      refInput?.current?.focus();
    }
  }, []);

  return (
    <InputElement
      ref={refInput}
      type={type}
      name={name}
      data-testid={name}
      placeholder={placeholder}
      value={value}
      min={min}
      max={max}
      isDisabled={isDisabled}
      isSoftDisabled={isSoftDisabled}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      onChange={onChange}
      spellCheck={isSpellchecked}
    />
  );
};
