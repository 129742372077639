import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Flex } from "../Box/Box";
import { Handle } from "./components/Handle";
import styled from "styled-components";
import { Track } from "./components/Track";
import { Text } from "../Text/Text";
import { Color } from "../Color/Color";
import { Tooltip } from "src/components";
import { Icon } from "../Icon/Icon";

const RangeContainer = styled(Flex)<{ isDisabled: boolean }>`
  pointer-events: ${({ isDisabled }) => isDisabled && "none"};
`;

const RangeInput = styled.input`
  width: 100%;
  margin: 0;
  opacity: 0;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`;

interface Props {
  refValue?: React.MutableRefObject<number | string>;
  title?: string;
  tooltip?: string;
  min?: number;
  max?: number;
  value?: number;
  setValue?: (value: number | string) => void;
  dataPoints?: (number | string)[];
  leftPlaceholder?: string;
  rightPlaceholder?: string;
  isDisabled?: boolean;
}

export const Range = forwardRef(
  (
    {
      refValue,
      value: _value,
      setValue: _setValue,
      title,
      tooltip,
      min,
      max,
      dataPoints,
      leftPlaceholder,
      rightPlaceholder,
      isDisabled = false,
    }: Props,
    ref: React.Ref<{ setValue: (value: number) => void }>,
  ) => {
    const [value, setValue] = useState(
      dataPoints
        ? dataPoints.findIndex((dp) => dp === (refValue ? refValue.current : _value))
        : _value || 0,
    );

    const minValue = (dataPoints && 0) || min || 0;
    const maxValue = (dataPoints && dataPoints.length - 1) || max || 10;
    const percentage = (value * 100) / maxValue;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newInnerValue = Number(event.target.value);
      const newOuterValue = (dataPoints && dataPoints[newInnerValue]) || newInnerValue;

      // Change inner state value.
      setValue(newInnerValue);

      // Change ref value.
      if (refValue !== undefined) {
        refValue.current = newOuterValue;
      }

      // Change outer state value.
      if (_setValue) {
        _setValue(newOuterValue);
      }
    };

    // Expose resetValue method via ref.
    useImperativeHandle(ref, () => ({
      setValue: (newOuterValue: number) => {
        const newInnerValue = dataPoints
          ? dataPoints.findIndex((dp) => dp === newOuterValue)
          : newOuterValue;

        setValue(newInnerValue);

        if (refValue) {
          refValue.current = newOuterValue;
        }

        if (_setValue) {
          _setValue(newOuterValue);
        }
      },
    }));

    return (
      <RangeContainer column isDisabled={isDisabled}>
        <Flex justify="between" align="center">
          {title && (
            <Text variant="body1" color={(isDisabled && Color.textDisabled) || Color.textSecondary}>
              {title}
            </Text>
          )}

          {tooltip && (
            <Flex>
              <Icon
                name="info"
                color={(isDisabled && Color.textDisabled) || Color.spaceGrayHover}
              />
              <Tooltip>{tooltip}</Tooltip>
            </Flex>
          )}
        </Flex>

        <Flex position="relative" margin="20px 2px 1px 2px">
          <Track percentage={percentage} isDisabled={isDisabled} />
          <Handle
            percentage={percentage}
            value={(dataPoints && dataPoints[value]) || value}
            isDisabled={isDisabled}
          />

          <RangeInput
            type="range"
            value={value}
            min={minValue}
            max={maxValue}
            onChange={onChange}
            disabled={isDisabled}
          />
        </Flex>

        <Flex justify="between" pointerEvents="none">
          <Text
            variant="caption3"
            color={(isDisabled && Color.textDisabled) || Color.textSecondary}
          >
            {leftPlaceholder}
          </Text>

          <Text
            variant="caption3"
            color={(isDisabled && Color.textDisabled) || Color.textSecondary}
          >
            {rightPlaceholder}
          </Text>
        </Flex>
      </RangeContainer>
    );
  },
);
