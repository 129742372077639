import { Grid, Flex, Button, Range } from "src/elements";
import {
  relaxFactorDataPointsFromZeroToOne,
  relaxFactorDataPointsFromZeroToInfinity,
} from "src/modals/StorePlanogramRules/store/relaxFactorDataPoints";
import { RulesSectionContainer } from "./components/RulesSectionContainer";
import React, { useRef } from "react";
import { defaultPspRules } from "src/pages/Merchflows_merchflowId_BasePog_basePogId_/store/types";
import { useModals } from "../Modals";

interface Props {
  isDisabled?: boolean;
  refEitherOr: React.MutableRefObject<number | string>;
  refBothOrNone: React.MutableRefObject<number | string>;
  refPreReq: React.MutableRefObject<number | string>;
  refShelfItemCount: React.MutableRefObject<number | string>;
  refFixedNotchNum: React.MutableRefObject<number | string>;
  refFingerSpace: React.MutableRefObject<number | string>;
  refUnitsNotEnoughDos: React.MutableRefObject<number | string>;
  refUnitsNotEnoughMos: React.MutableRefObject<number | string>;
  refUnitsTooMany: React.MutableRefObject<number | string>;
  refFacingsNotEnough: React.MutableRefObject<number | string>;
  refFacingsTooMany: React.MutableRefObject<number | string>;
  refMinWidth: React.MutableRefObject<number | string>;
  refMinDistribution: React.MutableRefObject<number | string>;
  refWidthSlack: React.MutableRefObject<number | string>;
  refCdt1Contiguous: React.MutableRefObject<number | string>;
  refCdt2Contiguous: React.MutableRefObject<number | string>;
  refCdt3Contiguous: React.MutableRefObject<number | string>;
  refCdt1Order: React.MutableRefObject<number | string>;
  refCdt2Order: React.MutableRefObject<number | string>;
  refCdt3Order: React.MutableRefObject<number | string>;
  refItemIdOrder: React.MutableRefObject<number | string>;
  refItemSpan: React.MutableRefObject<number | string>;
  refCdt1Span: React.MutableRefObject<number | string>;
  refCdt2Span: React.MutableRefObject<number | string>;
  refCdt3Span: React.MutableRefObject<number | string>;
  refSecondaryOrientation: React.MutableRefObject<number | string>;
}

export const OptimizationOptionsRules = ({
  isDisabled,
  refEitherOr,
  refBothOrNone,
  refPreReq,
  refShelfItemCount,
  refFixedNotchNum,
  refFingerSpace,
  refUnitsNotEnoughDos,
  refUnitsNotEnoughMos,
  refUnitsTooMany,
  refFacingsNotEnough,
  refFacingsTooMany,
  refMinWidth,
  refMinDistribution,
  refWidthSlack,
  refCdt1Contiguous,
  refCdt2Contiguous,
  refCdt3Contiguous,
  refCdt1Order,
  refCdt2Order,
  refCdt3Order,
  refItemIdOrder,
  refItemSpan,
  refCdt1Span,
  refCdt2Span,
  refCdt3Span,
  refSecondaryOrientation,
}: Props) => {
  const { openConfirmModal } = useModals();

  const refRangeEitherOr = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeBothOrNone = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangePreReq = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeShelfItemCount = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeFixedNotchNum = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeFingerSpace = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeUnitsNotEnoughDos = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeUnitsNotEnoughMos = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeUnitsTooMany = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeFacingsNotEnough = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeFacingsTooMany = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeMinWidth = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeMinDistribution = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeWidthSlack = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt1Contiguous = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt2Contiguous = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt3Contiguous = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt1Order = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt2Order = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt3Order = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeItemIdOrder = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeItemSpan = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt1Span = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt2Span = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeCdt3Span = useRef<{ setValue: (value: number | string) => void }>(null);
  const refRangeSecondaryOrientation = useRef<{ setValue: (value: number | string) => void }>(null);

  const confirmResetOptimizationOptions = () => {
    openConfirmModal({
      title: "Reset Optimization Options?",
      description:
        "Are you sure that you want to reset all optimization options values to their defaults?",
      buttons: [
        {
          label: "Keep Current Values",
          color: "primary",
          variant: "inverted",
        },
        {
          label: "Reset to Defaults",
          color: "yellow",
          variant: "default",
          onClick: resetOptimizationOptions,
        },
      ],
    });
  };

  const resetOptimizationOptions = () => {
    refRangeEitherOr.current?.setValue(defaultPspRules.relax_factors.either_or);
    refRangeBothOrNone.current?.setValue(defaultPspRules.relax_factors.both_or_none);
    refRangePreReq.current?.setValue(defaultPspRules.relax_factors.pre_req);
    refRangeShelfItemCount.current?.setValue(defaultPspRules.relax_factors.shelf_item_count);
    refRangeFixedNotchNum.current?.setValue(defaultPspRules.relax_factors.fixed_notch_num);
    refRangeFingerSpace.current?.setValue(defaultPspRules.relax_factors.finger_space);
    refRangeUnitsNotEnoughDos.current?.setValue(defaultPspRules.relax_factors.units_not_enough_dos);
    refRangeUnitsNotEnoughMos.current?.setValue(defaultPspRules.relax_factors.units_not_enough_mos);
    refRangeUnitsTooMany.current?.setValue(defaultPspRules.relax_factors.units_too_many);
    refRangeFacingsNotEnough.current?.setValue(defaultPspRules.relax_factors.facings_not_enough);
    refRangeFacingsTooMany.current?.setValue(defaultPspRules.relax_factors.facings_too_many);
    refRangeMinWidth.current?.setValue(defaultPspRules.relax_factors.min_width);
    refRangeMinDistribution.current?.setValue(defaultPspRules.relax_factors.min_distribution);
    refRangeWidthSlack.current?.setValue(defaultPspRules.relax_factors.width_slack);
    refRangeCdt1Contiguous.current?.setValue(defaultPspRules.relax_factors.cdt1_contiguous);
    refRangeCdt2Contiguous.current?.setValue(defaultPspRules.relax_factors.cdt2_contiguous);
    refRangeCdt3Contiguous.current?.setValue(defaultPspRules.relax_factors.cdt3_contiguous);
    refRangeCdt1Order.current?.setValue(defaultPspRules.relax_factors.cdt1_order);
    refRangeCdt2Order.current?.setValue(defaultPspRules.relax_factors.cdt2_order);
    refRangeCdt3Order.current?.setValue(defaultPspRules.relax_factors.cdt3_order);
    refRangeItemIdOrder.current?.setValue(defaultPspRules.relax_factors.item_id_order);
    refRangeItemSpan.current?.setValue(defaultPspRules.relax_factors.item_span);
    refRangeCdt1Span.current?.setValue(defaultPspRules.relax_factors.cdt1_span);
    refRangeCdt2Span.current?.setValue(defaultPspRules.relax_factors.cdt2_span);
    refRangeCdt3Span.current?.setValue(defaultPspRules.relax_factors.cdt3_span);
    refRangeSecondaryOrientation.current?.setValue(
      defaultPspRules.relax_factors.secondary_orientation,
    );
  };

  return (
    <Grid
      areas={`
        "quantity-on-shelf  cdt-contiguity"
        "empty-space        cdt-contiguity"
        "others             others"
        "reset              reset"
      `}
      gap="20px"
    >
      <RulesSectionContainer
        title="Quantity on Shelf"
        area="quantity-on-shelf"
        isDisabled={isDisabled}
      >
        <Range
          ref={refRangeUnitsNotEnoughMos}
          refValue={refUnitsNotEnoughMos}
          title="units_not_enough_mos"
          tooltip="units_not_enough_mos"
          dataPoints={relaxFactorDataPointsFromZeroToOne}
          leftPlaceholder="Stick to input"
          rightPlaceholder="Relax"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeUnitsNotEnoughDos}
          refValue={refUnitsNotEnoughDos}
          title="units_not_enough_dos"
          tooltip="units_not_enough_dos"
          dataPoints={relaxFactorDataPointsFromZeroToOne}
          leftPlaceholder="Stick to input"
          rightPlaceholder="Relax"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeFacingsNotEnough}
          refValue={refFacingsNotEnough}
          title="facings_not_enough"
          tooltip="facings_not_enough"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeFacingsTooMany}
          refValue={refFacingsTooMany}
          title="facings_too_many"
          tooltip="facings_too_many"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />
      </RulesSectionContainer>

      <RulesSectionContainer
        title="CDT Contiguity / Ordering"
        area="cdt-contiguity"
        isDisabled={isDisabled}
      >
        <Range
          ref={refRangeCdt1Contiguous}
          refValue={refCdt1Contiguous}
          title="cdt1_contiguous"
          tooltip="cdt1_contiguous"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt2Contiguous}
          refValue={refCdt2Contiguous}
          title="cdt2_contiguous"
          tooltip="cdt2_contiguous"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt3Contiguous}
          refValue={refCdt3Contiguous}
          title="cdt3_contiguous"
          tooltip="cdt3_contiguous"
          value={1}
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt1Order}
          refValue={refCdt1Order}
          title="cdt1_order"
          tooltip="cdt1_order"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt2Order}
          refValue={refCdt2Order}
          title="cdt2_order"
          tooltip="cdt2_order"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt3Order}
          refValue={refCdt3Order}
          title="cdt3_order"
          tooltip="cdt3_order"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />
      </RulesSectionContainer>

      <RulesSectionContainer title="Empty Space" area="empty-space" isDisabled={isDisabled}>
        <Range
          ref={refRangeWidthSlack}
          refValue={refWidthSlack}
          title="width_slack"
          tooltip="width_slack"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />
      </RulesSectionContainer>

      <RulesSectionContainer title="Others" area="others" isDisabled={isDisabled}>
        <Range
          ref={refRangeEitherOr}
          refValue={refEitherOr}
          title="either_or"
          tooltip="either_or"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeBothOrNone}
          refValue={refBothOrNone}
          title="both_or_none"
          tooltip="both_or_none"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangePreReq}
          refValue={refPreReq}
          title="prereq"
          tooltip="prereq"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeShelfItemCount}
          refValue={refShelfItemCount}
          title="shelf_item_count"
          tooltip="shelf_item_count"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeFixedNotchNum}
          refValue={refFixedNotchNum}
          title="fixed_notch_num"
          tooltip="fixed_notch_num"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeFingerSpace}
          refValue={refFingerSpace}
          title="finger_space"
          tooltip="finger_space"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeUnitsTooMany}
          refValue={refUnitsTooMany}
          title="units_too_many"
          tooltip="units_too_many"
          dataPoints={relaxFactorDataPointsFromZeroToOne}
          leftPlaceholder="Stick to input"
          rightPlaceholder="Relax"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeMinWidth}
          refValue={refMinWidth}
          title="min_width"
          tooltip="min_width"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeMinDistribution}
          refValue={refMinDistribution}
          title="min_distribution"
          tooltip="min_distribution"
          min={0}
          max={100}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeItemIdOrder}
          refValue={refItemIdOrder}
          title="item_id_order"
          tooltip="item_id_order"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeItemSpan}
          refValue={refItemSpan}
          title="item_span"
          tooltip="item_span"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt1Span}
          refValue={refCdt1Span}
          title="cdt1_span"
          tooltip="cdt1_span"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt2Span}
          refValue={refCdt2Span}
          title="cdt2_span"
          tooltip="cdt2_span"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeCdt3Span}
          refValue={refCdt3Span}
          title="cdt3_span"
          tooltip="cdt3_span"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />

        <Range
          ref={refRangeSecondaryOrientation}
          refValue={refSecondaryOrientation}
          title="secondary_orientation"
          tooltip="secondary_orientation"
          dataPoints={relaxFactorDataPointsFromZeroToInfinity}
          leftPlaceholder="Relax"
          rightPlaceholder="Stick to input"
          isDisabled={isDisabled}
        />
      </RulesSectionContainer>

      <Flex gridArea="reset" justify="center">
        <Button color="yellow" onClick={confirmResetOptimizationOptions}>
          Reset All Optimization Options
        </Button>
      </Flex>
    </Grid>
  );
};
