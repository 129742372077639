import React from "react";
import { Color, Flex, HorizontalDivider, Text } from "src/elements";

interface Props {
  title: string;
  area: string;
  isDisabled?: boolean;
  children: React.ReactNode;
}

export const RulesSectionContainer = ({ title, area, isDisabled, children }: Props) => (
  <Flex
    width="100%"
    background={Color.lightGray}
    column
    borderRadius="3px"
    overflow="hidden"
    gridArea={area}
  >
    <Flex
      background={Color.lightGrayHover}
      padding="5px 10px"
      justify="center"
      align="center"
      gap="10px"
    >
      <Text variant="body1" color={(isDisabled && Color.textDisabled) || Color.textMain}>
        {title}
      </Text>
    </Flex>

    <HorizontalDivider color={Color.textDisabled} />

    <Flex column padding="10px">
      {children}
    </Flex>
  </Flex>
);
