import { Color, Radios } from "src/elements";

export interface PlanogramOptimizeOnProps {
  optimizeOn: "SALES" | "PROFIT" | "DISPERSION";
  setOptimizeOn: (optimizeOn: PlanogramOptimizeOnProps["optimizeOn"]) => void;
  isDisabled?: boolean;
}

export const PlanogramOptimizeOn = ({ optimizeOn, setOptimizeOn, isDisabled }: PlanogramOptimizeOnProps) => (
  <Radios
    label="Optimize On:"
    options={[
      {
        label: "Sales",
        value: "SALES",
      },
      {
        label: "Profit",
        value: "PROFIT",
      },
      {
        label: "Dispersion",
        value: "DISPERSION",
      },
    ]}
    value={optimizeOn}
    setValue={setOptimizeOn}
    isDisabled={isDisabled}
  />
);
